$(document).ready(function () {

  initPortfolio();
  initModal();
  initValidation();



  function initPortfolio() {

    $('.fb-our-few-projects').on('click','.display-container .list-icons img', function () {
      var type = $(this).parents('.list-icons').attr('data-type');

      $(this).parents('.display-container').removeClass('macbook');
      $(this).parents('.display-container').removeClass('ipad');
      $(this).parents('.display-container').removeClass('iphone');

      $(this).parents('.display-container').addClass(type);

    });

    $('.fb-our-few-projects').on('click','.select-job ul li button', function () {
      var type  = $(this).attr('data-type');
      $(this).parents('ul').find('li').removeClass('active');
      $(this).parent('li').addClass('active');
      $('.display-container iframe').removeClass('active');
      $(this).parents('.fb-our-few-projects').find('.display-container iframe[data-type="'+type+'"]').addClass('active');
    });

  }


  function initModal() {

    $('body').on('click', '.open-modal', function (e) {
      e.preventDefault();


      console.log('click')
      var modalId = $(this).attr('data-id');

      console.log(modalId)
      MicroModal.show(modalId, {
        onShow: function (modal) {
          $('body').addClass('modal-open');
          clearForms();
        } ,
        onClose: function (modal) {
          $('body').removeClass('modal-open');
          clearForms();
        }
      });
    });
  }


  function clearForms() {
    var form_group = $('.form-group');
    form_group.removeClass('valid').removeClass('invalid');
    form_group.find('input').val('');
    form_group.find('textarea').html('');
  }


  function initValidation() {

    $('#send-test-letter-form').click(function (e) {
      e.preventDefault();
      validFormEmails();
    });

    $('#send-contact-us-form').click(function (e) {
      e.preventDefault();
      validFormContacts();
    });


    function validFormEmails() {

      var $form = $('#test-letter-form');

      var field_name = $form.find('input[name="your_name"]');
      var field_email = $form.find('input[name="your_email"]');

      var error = [];
      if(field_name) {
        if(field_name.val() == '' || field_name.val() == ' ') {
          error.push({name:'your_name', error: "incorrect field"});
          setInValid(field_name);
        } else {
          setValid(field_name);
        }
      }


      if(field_email) {
        if(field_email.val() == '') {
          error.push({name:'your_email', error: "incorrect field"});
          setInValid(field_email);
        } else if (!validateEmail(field_email.val())) {
          error.push({name:'your_email', error: "incorrect field"});
          setInValid(field_email);
        } else {
          setValid(field_email);
        }
      }


      if(error.length > 0) {
        return false;
      }


      // sendForm

    }


    function validFormContacts() {

      var $form = $('#contact-us-form');

      var field_name = $form.find('input[name="your_name"]');
      var field_email = $form.find('input[name="your_email"]');
      var field_phone = $form.find('input[name="your_email"]');
      var field_text = $form.find('textarea[name="your_text"]');

      var error = [];
      if(field_name) {
        if(field_name.val() == '' || field_name.val() == ' ') {
          error.push({name:'your_name', error: "incorrect field"});
          setInValid(field_name);
        } else {
          setValid(field_name);
        }
      }


      if(field_email) {
        if(field_email.val() == '') {
          error.push({name:'your_email', error: "incorrect field"});
          setInValid(field_email);
        } else if (!validateEmail(field_email.val())) {
          error.push({name:'your_email', error: "incorrect field"});
          setInValid(field_email);
        } else {
          setValid(field_email);
        }
      }


      if(field_text) {
        if(field_text.val() == '' || field_text.val() == ' ') {
          error.push({name:'your_text', error: "incorrect field"});
          setInValid(field_text);
        } else {
          setValid(field_text);
        }
      }

      if(error.length > 0) {
        return false;
      }


      // sendForm

    }

    function setValid(field) {
      field.parents('.form-group').removeClass('invalid').addClass('valid');
    }
    function setInValid(field) {
      field.parents('.form-group').removeClass('valid').addClass('invalid');
    }

    function validateEmail(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
  }









});